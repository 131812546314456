import React from 'react';
import { Seo } from '../../components/Seo';
import Layout from '../../templates/MainTemplate';
import { PrivacyText } from '../../view/privacy/privacyText/privacyText';

export default function Privacy(){
    return (
      <>
        <Seo title="Privacidade" />
        <Layout>
          <PrivacyText />
        </Layout>
      </>
    );
}