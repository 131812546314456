import React from "react";
import { StyledPrivacyText } from "./styledPrivacyText";
import { usePrivacy } from "../../../hooks/methods/privacy";
import { Loading } from "../../../components/Loading/Loading";

export function PrivacyText() {
  const { data, isSuccess, isLoading } = usePrivacy();

  return (
    <>
      {isLoading && <Loading />}
      {isSuccess && !isLoading && (
        <StyledPrivacyText
          dangerouslySetInnerHTML={{
            __html: data?.privacyPage?.privacyText.html,
          }}
        />
      )}
    </>
  );
}
